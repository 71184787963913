<template>
  <a-form-model class="form-language-modal" ref="languageForm" :model="form" :rules="rules">
    <div class="form-language-modal__block">
      <a-form-model-item label="Select language" prop="language_id" :colon="false">
        <UniversalSelect
          v-model="form.language_id"
          :options="languages"
          placeholder="Select language"
          :disabled="loading"
          :showSearch="withSearch"
          :allowClear="!readonly"
          @change="changeLanguage"
        />
      </a-form-model-item>
      <a-form-model-item label="Wheel name" prop="wheel_name" :colon="false">
        <a-input v-model="form.wheel_name" placeholder="Wheel name" size="large" :disabled="loading || readonly" />
      </a-form-model-item>
    </div>
    <div class="form-language-modal__block">
      <a-form-model-item label="Wheel description" prop="wheel_description" :colon="false">
        <a-input v-model="form.wheel_description" placeholder="Wheel description" size="large" :disabled="loading || readonly" />
      </a-form-model-item>
      <a-form-model-item label="Spin button name" prop="button_name" :colon="false">
        <a-input v-model="form.button_name" placeholder="Spin button name" size="large" :disabled="loading || readonly" />
      </a-form-model-item>
    </div>
    <a-row class="footer-viewscreen">
      <a-col class="text-right" :span="24">
        <a-button @click="closeModal">Cancel</a-button>
        <a-button
          v-if="!readonly"
          type="primary"
          class="ml-2"
          :loading="loading"
          @click="onSubmit"
        >
          {{ selectedLanguage ? 'Save' : 'Add' }}
        </a-button>
      </a-col>
    </a-row>
  </a-form-model>
</template>
<script>
import UniversalSelect from '@/components/custom/filters/universalSelect.vue'

export default {
  props: {
    selectedLanguages: {
      type: Array,
      default: () => [],
    },
    selectedLanguage: {
      type: [Object, null],
      default: null,
    },
    languageOptions: {
      type: Array,
      default: () => [],
    },
    withSearch: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    UniversalSelect,
  },
  data: () => ({
    form: {
      language_id: undefined,
      wheel_name: '',
      wheel_description: '',
      button_name: '',
      is_default: false,
    },
    rules: {
      language_id: [
        {
          required: true,
          message: 'Language is required',
          trigger: ['blur', 'change'],
        },
      ],
      wheel_name: [
        {
          required: true,
          message: 'Wheel name is required',
          trigger: ['focus', 'blur', 'change'],
        },
      ],
      wheel_description: [
        {
          required: true,
          message: 'Wheel description is required',
          trigger: ['blur', 'change'],
        },
      ],
      button_name: [
        {
          required: true,
          message: 'Button name is required',
          trigger: ['blur', 'change'],
        },
      ],
    },
    loading: false,
  }),
  computed: {
    languages() {
      if (this.readonly) {
        return this.languageOptions
      }
      return this.languageOptions.filter(lang => {
        if (this.selectedLanguage) {
          return !this.selectedLanguages.find(el => el.language_id === lang.value) || lang.value === this.selectedLanguage.language_id
        } else {
          return !this.selectedLanguages.find(el => el.language_id === lang.value)
        }
      })
    },
  },
  methods: {
    changeLanguage(langId) {
      if (!this.readonly) {
        return
      }
      const newLang = this.selectedLanguages.find(lang => lang.language_id === langId)
      if (newLang) {
        this.form.wheel_name = newLang.wheel_name
        this.form.wheel_description = newLang.wheel_description
        this.form.button_name = newLang.button_name
      }
    },
    languageDataFilling() {
      this.form.language_id = this.selectedLanguage.language_id
      this.form.wheel_name = this.selectedLanguage.wheel_name
      this.form.wheel_description = this.selectedLanguage.wheel_description
      this.form.button_name = this.selectedLanguage.button_name
      this.form.is_default = this.selectedLanguage?.is_default || false
    },
    onSubmit() {
      this.$refs.languageForm.validate((valid) => {
        if (valid) {
          this.$emit('save', this.form)
          this.closeModal()
        } else {
          return false
        }
      })
    },
    closeModal() {
      this.$emit('close')
    },
  },
  async mounted() {
    if (this.selectedLanguage) {
      this.languageDataFilling()
    }
  },
}
</script>

<style lang="scss">
.footer-viewscreen {
  margin-left: -24px !important;
  margin-right: -24px !important;
  border-top: 1px solid #d9d9d9;
  padding: 10px 24px 0 24px !important;
}
.form-language-modal {
  &__block {
    width: 100%;
    display: flex;
    gap: 12px;
    justify-content: space-between;
    & > div {
      width: 100%;
    }
  }
}
</style>
